<template>
	<div>

        <b-card v-if="overall" class="mb-2">
            <b-row>
                <b-col class="text-center" style="line-height: 1">
                    <span>Minuti Giocati</span>
                    <p class="lead mb-0"><strong>{{ overall.minutes }}</strong> <small class="text-muted">/{{ overall.maxTime }}</small></p>
                </b-col>
                <b-col class="text-center" style="line-height: 1">
                    <span>Partite Giocate</span>
                    <p class="lead mb-0"><strong>{{ overall.played }}</strong> <small class="text-muted">/{{ overall.matches }}</small></p>
                </b-col>
                <b-col class="text-center" style="line-height: 1">
                    <span>Gol</span>
                    <p class="lead mb-0"><strong>{{ overall.gol }}</strong></p>
                </b-col>
                <b-col class="text-center" style="line-height: 1">
                    <span>Ammonizioni</span>
                    <p class="lead mb-0"><strong>{{ overall.yellow }}</strong></p>
                </b-col>
                <b-col class="text-center" style="line-height: 1">
                    <span>Espulsioni</span>
                    <p class="lead mb-0"><strong>{{ overall.red }}</strong></p>
                </b-col>
            </b-row>
        </b-card>

        <b-table-simple small striped v-if="stats" :sticky-header="'60vh'">
            <b-thead>
                <b-tr>
                    <b-th width="15%">Data</b-th>
                    <b-th>Partita</b-th>
                    <b-th width="10%" class="text-center">Minuti</b-th>
                    <b-th width="10%" class="text-center">Gol</b-th>
                    <b-th width="10%" class="text-center">Ammonizioni</b-th>
                    <b-th width="10%" class="text-center">Espulsioni</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="match in stats">
                    <b-td>{{ match.partita.data | data }}</b-td>
                    <b-td>{{ match.partita.home.name }} - {{ match.partita.away.name }}</b-td>
                    <b-td class="text-center">{{ match.stats.minutes }}</b-td>
                    <b-td class="text-center">{{ match.stats.gol }}</b-td>
                    <b-td class="text-center">{{ match.stats.yellow }}</b-td>
                    <b-td class="text-center">{{ match.stats.red }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

	</div>
</template>

<script>

import moment from 'moment'

export default {
    props: [ 'player' ],
	data: function() {
		return {
			isLoading: true,
			stats: null,
		}
	},
    filters: {
        data: function(e) {
            return moment(e).format('DD/MM/YYYY H:mm')
        }
    },
    computed: {
        overall: function() {
            if(!this.stats) return false;

            var row = _.map(this.stats, 'stats');

            var output = {
                gol: _.sumBy(row, 'gol'),
                yellow: _.sumBy(row, 'yellow'),
                red: _.sumBy(row, 'red'),
                minutes: _.sumBy(row, 'minutes'),
                maxTime: _.sumBy(_.flatten(_.map(this.stats, 'partita.tempi')), 'durata_totale'),
                convocati: _.filter(row, function(a) { return a.titolare === true; }).length,
                played: _.filter(row, function(e) { return e.minutes > 0 }).length,
                matches: row.length
            };

            return output;

        }
    },
	methods: {
        fetchStats: function() {            
            var url = this.$store.state.apiEndPoint + '/persona/stats/' + this.player.persona.id;
            this.$http.get(url).then(function(data) {
                this.isLoading = false;
                this.stats = data.data;
                this.stats = _.sortBy(this.stats, ['partita.data']).reverse();
            }).catch((error) => {
                console.log(error)
                this.isLoading = false;
            });
        },
        fetchCarrieraWs: function() {
            if(this.osservato.wyscout) {
                this.isLoading = true;
                this.$http.get(this.$store.state.apiEndPoint + '/ws/get/career/player/' + this.osservato.wyscout.chiave).then((response) => {	
                    this.carriera = response.data;    
                }).finally(() => {
                    this.isLoading = false;
                });
            }else{
                this.isLoading = false;
            }
        },
	},
    created: function() {
        this.fetchStats();
    }
}

</script>

<style type="scss">

    .scouting-page-stats .carriera-stats-card{
        height: 60vh !important; 
        overflow: scroll;
    }
    
    .scouting-page-stats .carriera-stats-card thead{
        position: sticky;
        top: 0;
        background: white;
    }
    
    .scouting-page-stats .info-carriera-not-found{
        padding: 10px;
        border: 1px solid #A9021C;
        border-radius: 4px;
        color: #A9021C;
        font-size: 18px;
        text-align: center;
    }

</style>